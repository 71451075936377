




import {Component, Vue} from 'vue-property-decorator'
import {MetaInfo} from 'vue-meta'
import DappDetailed from '@/components/dappDetailed/DappDetailed.vue'

@Component({
  components: {DappDetailed},
})
export default class DappDetailedView extends Vue {
  metaInfo(): MetaInfo {
    return {
      title: this.$translate('metadata.title.dappDetailed', {
        dappName: this.$route.params.id,
      }),
    }
  }
}
